import React from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, message }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <p>{t(message)}</p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="confirm-button">{t("Save")}</button>
          <button onClick={onCancel} className="cancel-button">{t("Discard")}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
