// HomePage.js

import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageUpload from '../../pages/AdCreation/ImageUpload';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import AdForm from '../../pages/AdCreation/AdForm';
import Advertisement from '../../pages/AdCreation/Advertisement';
import Footer from '../../components/Footer/Footer';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import './HomePage.css';
import { useTranslation } from 'react-i18next';
import CheckoutForm from '../../components/CheckoutForm';

const stripePromise = loadStripe('pk_live_51LXIwaKFBLgdLlQOptTCquGgA2vq8adliMJlNBwMAIriZo2xGUtcufSJOJsGKQbFaIVrzMFtoMdLSB2GYGSIkn2d00symNrTwp');

const HomePage = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const [images, setImages] = useState([]);  // Ensure images has an initial empty array state
  const [mapScreenshot, setMapScreenshot] = useState(null);
  const [generatedAd, setGeneratedAd] = useState(null);
  const [credits, setCredits] = useState(0);
  const { t } = useTranslation();

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
  const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;

  useEffect(() => {
    const fetchCredits = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await axios.get(`${apiUrl}/get-credits`, {
            params: { user_id: user.sub },
          });
          setCredits(response.data.credits);
          localStorage.setItem('user_id', user.sub); // Ensure user_id is stored
        } catch (error) {
          console.error('Error fetching credits:', error);
        }
      }
    };

    fetchCredits();
  }, [isAuthenticated, user, apiUrl]);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
    localStorage.setItem('credits', newCredits);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    console.log('Redirecting to:', baseUrl);
    logout({ returnTo: baseUrl });
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <header className="header">
          <div className="logo-section">
            <div className="title">PicToAd</div>
          </div>
          <div className="right-section">
            <a href="http://www.deepreturn.ch" target="_blank" rel="noopener noreferrer">
              <img src={deepreturnLogo} alt="DeepReturn Logo" className="logo" />
            </a>
          </div>
        </header>
        <div className="header-controls-container">
          <div className="header-controls">
            <div className="control-group">
              <div className="language-switcher">
                <LanguageSwitcher />
              </div>
              {(isAuthenticated || localStorage.getItem('user_id')) && (
                <div className="credits-info">
                  {t('Credits')}: {credits}
                </div>
              )}
            </div>
            <div className="control-group">
              {isAuthenticated ? (
                <>
                  <Link to="/ad-history" className="view-history-button">
                    {t('View Ad History')}
                  </Link>
                  <Link to="/purchase-credits" className="buy-credits-button">
                    {t('Buy Credits')}
                  </Link>
                  <button className="logout-button" onClick={handleLogout}>
                    {t('Log Out')}
                  </button>
                </>
              ) : (
                <div className="login-info">
                  <Link to="/login">{t('Login')}</Link> {t('to access your account')}
                </div>
              )}
            </div>
          </div>
        </div>
        <ImageUpload images={images} setImages={setImages} />  {/* Pass both images and setImages */}
        <AdForm
          setGeneratedAd={setGeneratedAd}
          updateCredits={updateCredits}
          images={images}
          mapScreenshot={mapScreenshot}
          setMapScreenshot={setMapScreenshot}
        />
        {generatedAd && <Advertisement adContent={generatedAd} />}
        <Footer />
      </div>
    </Elements>
  );
};

export default HomePage;
