import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import Footer from '../../components/Footer/Footer';
import './AdHistoryPage.css';

const AdHistoryPage = () => {
  const { isAuthenticated, user } = useAuth0();
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedAdId, setCopiedAdId] = useState(null);
  const [filterOption, setFilterOption] = useState('last30days');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [selectedAd, setSelectedAd] = useState(null); // To handle the selected ad for modal
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

  // Fetch ads for authenticated user
  const fetchAds = async (userId) => {
    try {
      const response = await axios.get(`${apiUrl}/user_ads?user_id=${userId}`);
      if (response.data.ads && response.data.ads.length > 0) {
        const sortedAds = response.data.ads.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setAds(sortedAds);
        setFilteredAds(sortedAds);
      } else {
        setAds([]);
        setFilteredAds([]);
        setError(t('No ads found for your account.'));
      }
    } catch (err) {
      console.error('Error fetching ads:', err);
      setError(t('Error fetching ads. Please try again later.'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const storedUserId = localStorage.getItem('user_id');
      const userId = user ? user.sub : storedUserId;
      if (userId) {
        fetchAds(userId);
      } else {
        console.error('User ID not found.');
        setError(t('User ID not found.'));
        setLoading(false);
      }
    }
  }, [isAuthenticated, user, apiUrl, t]);

  useEffect(() => {
    const now = new Date();
    let filtered = [...ads];

    switch (filterOption) {
      case 'today':
        filtered = filtered.filter(ad => {
          const adDate = new Date(ad.created_at);
          return adDate.toDateString() === now.toDateString();
        });
        break;
      case 'last10days':
        const tenDaysAgo = new Date(now.getTime() - 10 * 24 * 60 * 60 * 1000);
        filtered = filtered.filter(ad => new Date(ad.created_at) >= tenDaysAgo);
        break;
      case 'last30days':
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        filtered = filtered.filter(ad => new Date(ad.created_at) >= thirtyDaysAgo);
        break;
      case 'custom':
        if (customStartDate && customEndDate) {
          const startDate = new Date(customStartDate);
          const endDate = new Date(customEndDate);
          filtered = filtered.filter(ad => {
            const adDate = new Date(ad.created_at);
            return adDate >= startDate && adDate <= endDate;
          });
        } else {
          filtered = [];
        }
        break;
      default:
        break;
    }

    setFilteredAds(filtered);
  }, [filterOption, customStartDate, customEndDate, ads]);

  const stripMarkdownAndHtml = (content) => {
    return content.replace(/<[^>]*>/g, '').replace(/[\*\_\#\>\-`]/g, '');
  };

  const copyToClipboard = (adId, content) => {
    const plainTextContent = stripMarkdownAndHtml(content);
    navigator.clipboard.writeText(plainTextContent).then(() => {
      setCopiedAdId(adId);
      setTimeout(() => setCopiedAdId(null), 2000);
    });
  };

  const openModal = (ad) => {
    setSelectedAd(ad); // Open modal for the selected ad
  };

  const closeModal = () => {
    setSelectedAd(null); // Close the modal
  };

  return (
    <div className="App ad-history-page">
      <header className="header">
        <div className="logo-section">
          <Link to="/app" className="logo-link">
            <div className="title">PicToAd</div>
          </Link>
        </div>
        <div className="right-section">
          <a href="http://www.deepreturn.ch" target="_blank" rel="noopener noreferrer">
            <img src={deepreturnLogo} alt="DeepReturn Logo" className="logo" />
          </a>
          <div className="language-switcher">
            <LanguageSwitcher />
          </div>
        </div>
      </header>

      <div className="header-controls-container">
        <div className="header-controls">
          <div className="control-group">
            {isAuthenticated && (
              <Link to="/app" className="back-to-home-button">
                {t('Back to Home')}
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Filter Menu */}
      <div className="filter-container">
        <div className="filter-options">
          <label>
            <input
              type="radio"
              value="today"
              checked={filterOption === 'today'}
              onChange={(e) => setFilterOption(e.target.value)}
            />
            {t('Today')}
          </label>
          <label>
            <input
              type="radio"
              value="last10days"
              checked={filterOption === 'last10days'}
              onChange={(e) => setFilterOption(e.target.value)}
            />
            {t('Last 10 Days')}
          </label>
          <label>
            <input
              type="radio"
              value="last30days"
              checked={filterOption === 'last30days'}
              onChange={(e) => setFilterOption(e.target.value)}
            />
            {t('Last 30 Days')}
          </label>
          <label>
            <input
              type="radio"
              value="custom"
              checked={filterOption === 'custom'}
              onChange={(e) => setFilterOption(e.target.value)}
            />
            {t('Custom Range')}
          </label>
        </div>
        {filterOption === 'custom' && (
          <div className="date-range-selects">
            <label>
              {t('Start Date')}:
              <input
                type="date"
                value={customStartDate}
                onChange={(e) => setCustomStartDate(e.target.value)}
              />
            </label>
            <label>
              {t('End Date')}:
              <input
                type="date"
                value={customEndDate}
                onChange={(e) => setCustomEndDate(e.target.value)}
              />
            </label>
          </div>
        )}
      </div>

      <div className="ad-history-container">
        {loading ? (
          <div className="loading-message">{t('Loading...')}</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : filteredAds.length === 0 ? (
          <div className="no-ads-message">{t('No ads found for your account.')}</div>
        ) : (
          <div className="ad-history-list">
            {filteredAds.map((ad) => (
              <div key={ad.ad_id} className="ad-history-item" onClick={() => openModal(ad)}>
                <div className="ad-card">
                  <div className="ad-card-header">
                    <h2 className="ad-title">{ad.address || t('Advertisement')}</h2>
                  </div>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]} className="ad-preview">
                    {ad.ad_content.slice(0, 200) + '...'}
                  </ReactMarkdown>
                  <p className="ad-date">
                    {t('Created At')}: {new Date(ad.created_at).toLocaleString()}
                    <button
                      className="copy-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(ad.ad_id, ad.ad_content);
                      }}
                      title={t('Copy to clipboard')}
                    >
                      <FaRegCopy />
                    </button>
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedAd && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>{selectedAd.address || t('Advertisement')}</h2>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} className="ad-content">
              {selectedAd.ad_content}
            </ReactMarkdown>
            <button
              className={`copy-button ${copiedAdId === selectedAd.ad_id ? 'copied' : ''}`}
              onClick={() => copyToClipboard(selectedAd.ad_id, selectedAd.ad_content)}
              title={t('Copy to clipboard')}
            >
              {copiedAdId === selectedAd.ad_id ? t('Copied!') : <FaRegCopy />}
            </button>
            <p className="ad-date">
              {t('Created At')}: {new Date(selectedAd.created_at).toLocaleString()}
            </p>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default AdHistoryPage;
