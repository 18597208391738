import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Success.css';
import { useTranslation } from 'react-i18next';

const Success = () => {
  const { user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Use dynamic API URL
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';

useEffect(() => {
  const query = new URLSearchParams(location.search);
  const session_id = query.get('session_id');
  const user_id = query.get('user_id');
  const credits = query.get('credits');

  console.log('Success Component - Query Parameters:', { session_id, user_id, credits }); // Log the received query parameters

  if (session_id && user_id && credits) {
    console.log('Updating credits with session_id:', session_id, 'user_id:', user_id, 'credits:', credits);
    axios.post(`${apiUrl}/update-credits`, { user_id, credits: parseInt(credits) })
      .then(response => {
        console.log('Credits updated successfully:', response.data);
        // Fetch the updated credits after successful update
        return axios.get(`${apiUrl}/get-credits?user_id=${user_id}`);
      })
      .then(response => {
        console.log('Updated credits fetched successfully:', response.data);
      })
      .catch(error => {
        console.error('Error during credit update or fetch:', error.response?.data || error.message);
      });
  } else {
    console.error('Missing session_id, user_id, or credits in URL');
  }
}, [location, user, apiUrl]);

  const handleBackToMain = () => {
    navigate('/');
  };

  return (
    <div className="success-page">
      <div className="success-container">
        <h1>{t('Payment Successful!')}</h1>
        <p>{t('Your credits have been added to your account.')}</p>
        <button onClick={handleBackToMain} className="back-button">
          {t('Back to Main Page')}
        </button>
      </div>
    </div>
  );
};

export default Success;
