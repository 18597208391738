import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './config/i18n'; // Import the i18n configuration
import { Auth0Provider } from '@auth0/auth0-react';

const root = createRoot(document.getElementById('root'));

// Function to handle redirection after login
const onRedirectCallback = (appState) => {
  console.log('Auth0 onRedirectCallback triggered with appState:', appState);
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

// Ensure environment variables are logged for debugging purposes
console.log('Auth0 Domain:', process.env.REACT_APP_AUTH0_DOMAIN);
console.log('Auth0 Client ID:', process.env.REACT_APP_AUTH0_CLIENT_ID);
console.log('Auth0 Redirect URI:', window.location.origin);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || "deepreturn.us.auth0.com"}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || "uJ5tYgjyqhETn7PIABPbNoXpgpSrLJf3"}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    onRedirectCallback={onRedirectCallback}
    cacheLocation="localstorage" // Store the session in localStorage for persistence
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>
);
