import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiGlobe } from 'react-icons/fi'; // Globe icon for the main button
import './LanguageSwitcher.css';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setIsOpen(false); // Close dropdown on selection
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="language-switcher">
      <button className="language-button" onClick={toggleDropdown}>
        <FiGlobe size={20} className="globe-icon" /> {/* Main button icon */}
        <span className="dropdown-icon">&#x25BC;</span> {/* Dropdown Arrow */}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => changeLanguage('en')}>
            <span role="img" aria-label="United States" className="flag">🇺🇸</span> EN
          </div>
          <div className="dropdown-item" onClick={() => changeLanguage('de')}>
            <span role="img" aria-label="Germany" className="flag">🇩🇪</span> DE
          </div>
          <div className="dropdown-item" onClick={() => changeLanguage('fr')}>
            <span role="img" aria-label="France" className="flag">🇫🇷</span> FR
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
