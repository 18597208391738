import React from 'react';
import { useTranslation } from 'react-i18next';
import './Impressum.css';
import logo from '../../assets/images/deepreturn.png'; // Replace with your actual logo path

const Impressum = () => {
  const { t } = useTranslation();

  const handleBackClick = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <div className="impressum-container">
      <div className="impressum-card">
        <img src={logo} alt="Company Logo" className="impressum-logo" />
        <h1 className="impressum-title">{t('Impressum')}</h1>
        <div className="impressum-content">
          <p className="impressum-section">
            <strong>{t('companyInfoTitle')}</strong>
          </p>
          <p className="impressum-detail">{t('companyName')}</p>
          <p className="impressum-detail">{t('address')}</p>
          <p className="impressum-detail">
            <strong>Email:</strong> 
            <a href="mailto:info@deepreturn.ch" className="impressum-link">
              info@deepreturn.ch
            </a>
          </p>
          <p className="impressum-detail">
            <strong>UID:</strong> {t('uidNumber')}
          </p>
          <p className="impressum-detail">
            <strong>{t('managingDirector')}</strong>
          </p>
          <p className="impressum-detail">
            <strong>{t('commercialRegister')}</strong>
          </p>
        </div>
        <button className="back-button" onClick={handleBackClick}>
          {t('Back to Main Page')}
        </button>
      </div>
    </div>
  );
};

export default Impressum;
