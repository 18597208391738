// TestPage.js

import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ImageUpload from '../../pages/AdCreation/ImageUpload';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import AdFormRedeem from '../../pages/AdCreation/AdFormRedeem';
import Advertisement from '../../pages/AdCreation/Advertisement';
import Footer from '../../components/Footer/Footer';
import deepreturnLogo from '../../assets/images/deepreturn.png';
import './TestPage.css';
import { useTranslation } from 'react-i18next';
import CheckoutFormRedeem from '../../components/CheckoutFormRedeem';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51LXIwaKFBLgdLlQOptTCquGgA2vq8adliMJlNBwMAIriZo2xGUtcufSJOJsGKQbFaIVrzMFtoMdLSB2GYGSIkn2d00symNrTwp');

const TestPage = () => {
  const [images, setImages] = useState([]); // Lifted images state
  const [mapScreenshot, setMapScreenshot] = useState(null); // State for map screenshot
  const [generatedAd, setGeneratedAd] = useState(null);
  const [credits, setCredits] = useState(0);
  const { t } = useTranslation();

  const sessionToken = localStorage.getItem('session_token');
  const redeemCode = localStorage.getItem('redeem_code');

  // Dynamic API URL
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
  const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;

  useEffect(() => {
    const fetchCredits = async () => {
      if (sessionToken && redeemCode) {
        try {
          const response = await axios.post(`${apiUrl}/redeem-code`, { code: redeemCode });
          if (response.data.success) {
            setCredits(response.data.credits);
            localStorage.setItem('session_token', response.data.session_token);
            localStorage.setItem('redeem_code', response.data.code);
          } else {
            console.error('Error fetching credits', response.data.error);
          }
        } catch (error) {
          console.error('Error fetching credits', error);
        }
      } else {
        console.error('No session token or redeem code found');
      }
    };

    fetchCredits();
  }, [sessionToken, redeemCode, apiUrl]);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
    localStorage.setItem('credits', newCredits);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    localStorage.removeItem('session_token');
    localStorage.removeItem('redeem_code');
    localStorage.removeItem('credits');
    window.location.href = baseUrl; // Redirect to base URL
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <header className="header">
          <div className="logo-section">
            <div className="title">PicToAd Partner Zugang</div>
          </div>
          <div className="right-section">
            <a href="http://www.deepreturn.ch" target="_blank" rel="noopener noreferrer">
              <img src={deepreturnLogo} alt="DeepReturn Logo" className="logo" />
            </a>
          </div>
        </header>
        <div className="header-controls-container">
          <div className="header-controls">
            <div className="control-group">
              <div className="language-switcher">
                <LanguageSwitcher />
              </div>
              {redeemCode && (
                <div className="credits-info">
                  {t('Credits')}: {credits}
                </div>
              )}
            </div>
            <div className="control-group">
              {redeemCode && (
                <>
                  <Link to="/redeem-history" className="view-history-button">
                    {t('View Ad History')}
                  </Link>
                  <button className="logout-button" onClick={handleLogout}>
                    {t('Log Out')}
                  </button>
                  <Link to="/purchase-credits" className="buy-credits-button">
                    {t('Buy Credits')}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        
        {/* ImageUpload Component */}
        <ImageUpload images={images} setImages={setImages} />

        {/* AdFormRedeem Component */}
        <AdFormRedeem
          setGeneratedAd={setGeneratedAd}
          updateCredits={updateCredits}
          redeemCode={redeemCode}
          images={images} // Pass images as props
          mapScreenshot={mapScreenshot}
          setMapScreenshot={setMapScreenshot}
        />

        {/* Advertisement Component */}
        {generatedAd && <Advertisement adContent={generatedAd} />}

        <Footer />
      </div>
    </Elements>
  );
};

export default TestPage;
