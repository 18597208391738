import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Login.module.css';
import logo from '../../assets/images/ImmoAiLogo.png';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleLogin = async () => {
    await loginWithRedirect({
      ui_locales: i18n.language,
      appState: { returnTo: location.state?.from?.pathname || '/app' }
    });
  };

  const handleSignUp = async () => {
    await loginWithRedirect({
      ui_locales: i18n.language,
      screen_hint: 'signup', // This hints Auth0 to show the signup screen
      appState: { returnTo: location.state?.from?.pathname || '/app' }
    });
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginCard}>
        <img src={logo} alt={t("PicToAd by DeepReturn")} className={styles.logo} />
        <h1 className={styles.title}>{t('Welcome to PicToAd')}</h1>
        <p className={styles.description}>{t('Your AI-powered real estate ad creator.')}</p>
        <button onClick={handleLogin} className={styles.loginButton}>
          {t('Log In')}
        </button>
        {location.state?.fromTryItOut && (
          <p className={styles.signupPrompt}>
            {t("Don't have an account?")} <span onClick={handleSignUp} className={styles.signupLink}>{t('Sign up here')}</span>
          </p>
        )}
        <div className={styles.loginLanguageSwitcher}>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
};

export default Login;
