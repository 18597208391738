// ImageUpload.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageEditorModal from './ImageEditorModal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './ImageUpload.css';

const MAX_IMAGES = 10;
const ALLOWED_FORMATS = ['image/jpeg', 'image/png'];

function ImageUpload({ images, setImages }) {
  const { t } = useTranslation();
  const [dragOver, setDragOver] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  // Handle new images from file input or drag-and-drop
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files || e.dataTransfer.files);
    const unsupportedFiles = files.filter((file) => !ALLOWED_FORMATS.includes(file.type));

    if (unsupportedFiles.length > 0) {
      alert(`${t('Unsupported format')}: ${unsupportedFiles.map((file) => file.type).join(', ')}. ${t('Supported formats')}: ${ALLOWED_FORMATS.join(', ')}.`);
      return;
    }

    const validFiles = files.filter((file) => ALLOWED_FORMATS.includes(file.type));
    if (images.length + validFiles.length > MAX_IMAGES) {
      alert(t(`You can upload a maximum of ${MAX_IMAGES} images.`));
      return;
    }

    setImages([...images, ...validFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleClick = () => {
    document.getElementById('file-input').click();
  };

  // Open editor for a specific image
  const openEditor = (index = 0) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeEditor = () => {
    setIsModalOpen(false);
    setCurrentImageIndex(null);
  };

  const handleSaveImages = (modifiedImages) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      modifiedImages.forEach(({ file, index }) => {
        updatedImages[index] = file;
      });
      return updatedImages;
    });
  };

  // Download all images in a ZIP file
  const handleDownloadAllImages = async () => {
    const zip = new JSZip();
    for (const [index, file] of images.entries()) {
      const dataUrl = await fileToDataURL(file);
      const base64Data = dataUrl.split(',')[1];
      zip.file(`image${index + 1}.png`, base64Data, { base64: true });
    }

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'images.zip');
  };

  // Convert file to data URL for download
  const fileToDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div
      className={`image-upload-container ${dragOver ? 'drag-over' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <input
        id="file-input"
        type="file"
        multiple
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
      {!images.length && (
        <div className="drag-drop-text" onClick={handleClick}>
          {t('Drag and drop your images here or click to select images')}
          <div className="upload-info">
            {t('Suggested images:')}
            <ul>
              <li>{t('Indoor pictures')} 📸</li>
              <li>{t('Pictures of the surroundings')} 🌆</li>
              <li>{t('Floor plan (Grundrissplan) if possible')} 📐</li>
            </ul>
          </div>
        </div>
      )}
      <div className="image-preview-container">
        {images.map((file, index) => (
          <div key={index} className="image-preview" onClick={() => openEditor(index)}>
            <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
            <button type="button" onClick={(event) => {
              event.stopPropagation();
              handleRemoveImage(index);
            }}>✖️</button>
          </div>
        ))}
      </div>

      {images.length > 0 && (
        <div className="action-buttons">
          <button
            className="optimize-button"
            onClick={(e) => {
              e.stopPropagation();
              openEditor(0);
            }}
          >
            {t('Optimize Images')}
          </button>

          <button
            className="download-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadAllImages();
            }}
          >
            {t('Download All Images')}
          </button>
        </div>
      )}

      {isModalOpen && (
        <ImageEditorModal
          isOpen={isModalOpen}
          onClose={closeEditor}
          images={images}
          currentImageIndex={currentImageIndex}
          onSave={handleSaveImages}
        />
      )}
    </div>
  );
}

export default ImageUpload;
