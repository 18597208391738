import React, { useState, useEffect, useRef } from 'react';
import './ImageEditorModal.css';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConfirmationModal from './ConfirmationModal'; // Custom modal for confirmation

const ImageEditorModal = ({ isOpen, onClose, images, currentImageIndex, onSave }) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const imgRef = useRef(new Image());

  const [originalImageData, setOriginalImageData] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(currentImageIndex || 0);

  // Use a Set to track unsaved changes for each image
  const [unsavedChanges, setUnsavedChanges] = useState(new Set());

  // Use useRef for imageSettings to persist between renders
  const imageSettings = useRef({});

  // Sliders for filters (default values)
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [sharpness, setSharpness] = useState(0);
  const [noiseReduction, setNoiseReduction] = useState(0);
  const [gamma, setGamma] = useState(1);
  const [hue, setHue] = useState(0);
  const [advancedSettings, setAdvancedSettings] = useState(false);

  // Confirmation modal state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    console.log("Image sources:", images);  // Ensure images array is as expected
    if (isOpen) {
      loadImage(activeImageIndex); // Load the initial image when modal is opened
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, activeImageIndex]);

const dataURLToFile = (dataUrl, filename) => {
    if (!dataUrl || typeof dataUrl !== 'string' || !dataUrl.includes(',')) {
        console.error("Invalid data URL provided:", dataUrl);
        return null;
    }
    const [header, base64String] = dataUrl.split(',');
    const mimeMatch = header.match(/:(.*?);/);
    if (!mimeMatch) {
        console.error("MIME type extraction failed from data URL:", dataUrl);
        return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(base64String);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};



  const loadImage = (index) => {
    const img = imgRef.current;
    img.src = URL.createObjectURL(images[index]); // Convert File object to URL
    img.crossOrigin = 'Anonymous';
    
    img.onload = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        setOriginalImageData(imageData);

        const settings = imageSettings.current[index] || {
          brightness: 0,
          contrast: 0,
          saturation: 0,
          sharpness: 0,
          noiseReduction: 0,
          gamma: 1,
          hue: 0,
        };
        applyImageSettings(settings);
      }
    };

    img.onerror = (err) => {
      console.error(`Failed to load image at index ${index}:`, err);
    };
  };



  // Apply the loaded image settings to the filter states
  const applyImageSettings = (settings) => {
    setBrightness(settings.brightness);
    setContrast(settings.contrast);
    setSaturation(settings.saturation);
    setSharpness(settings.sharpness);
    setNoiseReduction(settings.noiseReduction);
    setGamma(settings.gamma);
    setHue(settings.hue);
  };

  // Save the current image settings to the imageSettings object
  const saveCurrentImageSettings = () => {
    const currentSettings = {
      brightness,
      contrast,
      saturation,
      sharpness,
      noiseReduction,
      gamma,
      hue,
    };

    // Save white balance gains if they exist
    if (imageSettings.current[activeImageIndex]?.whiteBalanceApplied) {
      currentSettings.redGain = imageSettings.current[activeImageIndex].redGain;
      currentSettings.greenGain = imageSettings.current[activeImageIndex].greenGain;
      currentSettings.blueGain = imageSettings.current[activeImageIndex].blueGain;
      currentSettings.whiteBalanceApplied = true;
    }

    imageSettings.current[activeImageIndex] = currentSettings;
  };

  // Apply filters to the current image
  const applyFilters = () => {
    const canvas = canvasRef.current;
    if (!canvas || !originalImageData) return;
    const ctx = canvas.getContext('2d');

    // Reset to original image data
    ctx.putImageData(originalImageData, 0, 0);
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // Apply white balance correction if gains are set
    const settings = imageSettings.current[activeImageIndex];
    if (settings?.whiteBalanceApplied) {
      applyWhiteBalanceCorrection(imageData, settings.redGain, settings.greenGain, settings.blueGain);
    }

    // Apply filters step by step
    imageData = applyBrightnessContrast(imageData, brightness, contrast);
    imageData = applySaturation(imageData, saturation);
    imageData = applyGamma(imageData, gamma);
    imageData = applyHueRotation(imageData, hue);
    imageData = applyNoiseReduction(imageData, noiseReduction);
    imageData = applySharpness(imageData, sharpness);

    ctx.putImageData(imageData, 0, 0); // Update the canvas with modified data
  };

  // Update useEffect to include originalImageData in dependencies
  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brightness, contrast, saturation, gamma, hue, sharpness, noiseReduction, originalImageData]);

  // Reset all filter settings to default
  const resetFilters = () => {
    setBrightness(0);
    setContrast(0);
    setSaturation(0);
    setSharpness(0);
    setNoiseReduction(0);
    setGamma(1);
    setHue(0);

    // Remove white balance settings
    if (imageSettings.current[activeImageIndex]?.whiteBalanceApplied) {
      delete imageSettings.current[activeImageIndex].redGain;
      delete imageSettings.current[activeImageIndex].greenGain;
      delete imageSettings.current[activeImageIndex].blueGain;
      delete imageSettings.current[activeImageIndex].whiteBalanceApplied;
    }
  };

  // Helper functions for applying filters
  const clampValue = (value, min, max) => Math.max(min, Math.min(max, value));

  const applyBrightnessContrast = (imageData, brightness, contrast) => {
    let data = imageData.data;
    // Apply brightness
    for (let i = 0; i < data.length; i += 4) {
      data[i] += brightness;
      data[i + 1] += brightness;
      data[i + 2] += brightness;
    }

    // Apply contrast
    const contrastFactor = (259 * (contrast + 255)) / (255 * (259 - contrast));
    for (let i = 0; i < data.length; i += 4) {
      data[i] = clampValue(contrastFactor * (data[i] - 128) + 128, 0, 255);
      data[i + 1] = clampValue(contrastFactor * (data[i + 1] - 128) + 128, 0, 255);
      data[i + 2] = clampValue(contrastFactor * (data[i + 2] - 128) + 128, 0, 255);
    }
    return imageData;
  };

  const applySaturation = (imageData, saturation) => {
    let data = imageData.data;
    const satFactor = (saturation / 100) + 1;
    for (let i = 0; i < data.length; i += 4) {
      const gray = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
      data[i] = clampValue(gray + (data[i] - gray) * satFactor, 0, 255);
      data[i + 1] = clampValue(gray + (data[i + 1] - gray) * satFactor, 0, 255);
      data[i + 2] = clampValue(gray + (data[i + 2] - gray) * satFactor, 0, 255);
    }
    return imageData;
  };

  const applyGamma = (imageData, gamma) => {
    let data = imageData.data;
    const gammaCorrection = 1 / gamma;
    for (let i = 0; i < data.length; i += 4) {
      data[i] = clampValue(255 * Math.pow(data[i] / 255, gammaCorrection), 0, 255);
      data[i + 1] = clampValue(255 * Math.pow(data[i + 1] / 255, gammaCorrection), 0, 255);
      data[i + 2] = clampValue(255 * Math.pow(data[i + 2] / 255, gammaCorrection), 0, 255);
    }
    return imageData;
  };

  const applyHueRotation = (imageData, hue) => {
    if (hue === 0) return imageData;
    let data = imageData.data;
    const radians = (hue * Math.PI) / 180;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    // Matrix transformation
    const matrix = [
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.140,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ];

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      data[i] = clampValue(r * matrix[0] + g * matrix[1] + b * matrix[2], 0, 255);
      data[i + 1] = clampValue(r * matrix[3] + g * matrix[4] + b * matrix[5], 0, 255);
      data[i + 2] = clampValue(r * matrix[6] + g * matrix[7] + b * matrix[8], 0, 255);
    }
    return imageData;
  };

  const applyNoiseReduction = (imageData, strength) => {
    if (strength === 0) return imageData;
    const kernelSize = strength * 2 + 1; // Ensure odd kernel size
    return convolve(imageData, getGaussianKernel(kernelSize));
  };

  const applySharpness = (imageData, strength) => {
    if (strength === 0) return imageData;
    const amount = strength / 10; // Normalize strength
    const sharpenedData = convolve(imageData, getSharpenKernel(amount));
    return sharpenedData;
  };

  // Generate Gaussian Kernel for Noise Reduction
  const getGaussianKernel = (size) => {
    const sigma = size / 6;
    const kernel = [];
    const mean = Math.floor(size / 2);
    let sum = 0;

    for (let x = 0; x < size; x++) {
      kernel[x] = [];
      for (let y = 0; y < size; y++) {
        const ex = Math.exp(-0.5 * (Math.pow((x - mean) / sigma, 2) + Math.pow((y - mean) / sigma, 2)));
        kernel[x][y] = ex / (2 * Math.PI * sigma * sigma);
        sum += kernel[x][y];
      }
    }

    // Normalize kernel
    for (let x = 0; x < size; x++) {
      for (let y = 0; y < size; y++) {
        kernel[x][y] /= sum;
      }
    }
    return kernel;
  };

  // Generate Sharpen Kernel
  const getSharpenKernel = (amount) => {
    return [
      [0, -amount, 0],
      [-amount, 1 + 4 * amount, -amount],
      [0, -amount, 0],
    ];
  };

  // Convolution function
  const convolve = (imageData, kernel) => {
    const pixels = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
    const side = kernel.length;
    const halfSide = Math.floor(side / 2);

    const output = new ImageData(width, height);
    const dst = output.data;

    // Iterate over every pixel
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        let r = 0,
          g = 0,
          b = 0;
        // Apply kernel
        for (let ky = 0; ky < side; ky++) {
          for (let kx = 0; kx < side; kx++) {
            const px = x + kx - halfSide;
            const py = y + ky - halfSide;
            if (px >= 0 && px < width && py >= 0 && py < height) {
              const offset = (py * width + px) * 4;
              const wt = kernel[ky][kx];
              r += pixels[offset] * wt;
              g += pixels[offset + 1] * wt;
              b += pixels[offset + 2] * wt;
            }
          }
        }
        const offset = (y * width + x) * 4;
        dst[offset] = clampValue(r, 0, 255);
        dst[offset + 1] = clampValue(g, 0, 255);
        dst[offset + 2] = clampValue(b, 0, 255);
        dst[offset + 3] = pixels[offset + 3]; // Copy alpha
      }
    }
    return output;
  };

  // Advanced auto-optimization function
  const autoOptimize = () => {
    const canvas = canvasRef.current;
    if (!canvas || !originalImageData) return;

    const ctx = canvas.getContext('2d');
    ctx.putImageData(originalImageData, 0, 0);

    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // 1. Histogram Analysis
    const { brightnessAdjustment, contrastAdjustment } = analyzeHistogram(imageData);
    setBrightness(brightnessAdjustment);
    setContrast(contrastAdjustment);

    // 2. Automatic White Balance Correction
    const { redGain, greenGain, blueGain } = computeWhiteBalanceGains(imageData);
    applyWhiteBalanceCorrection(imageData, redGain, greenGain, blueGain);

    // Store gains in imageSettings for later use
    imageSettings.current[activeImageIndex] = {
      ...imageSettings.current[activeImageIndex],
      redGain,
      greenGain,
      blueGain,
      whiteBalanceApplied: true,
    };

    ctx.putImageData(imageData, 0, 0); // Update canvas with white balance corrected data

    // Re-fetch imageData after white balance correction
    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    // 3. Adaptive Saturation Adjustment
    const saturationAdjustment = computeSaturationAdjustment(imageData);
    setSaturation(saturationAdjustment);

    // 4. Noise Estimation and Reduction
    const noiseLevel = estimateNoise(imageData);
    const noiseReductionValue = computeNoiseReductionValue(noiseLevel);
    setNoiseReduction(noiseReductionValue);

    // 5. Edge-Aware Sharpening
    const sharpnessValue = computeSharpnessValue(imageData);
    setSharpness(sharpnessValue);

    // Apply filters to see immediate effect
    applyFilters();
  };

  // Helper functions for auto-optimization

  // Histogram Analysis
  const analyzeHistogram = (imageData) => {
    const data = imageData.data;
    const histogram = new Array(256).fill(0);
    const totalPixels = data.length / 4;

    let luminanceSum = 0;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      const luminance = Math.round(0.299 * r + 0.587 * g + 0.114 * b);
      histogram[luminance]++;
      luminanceSum += luminance;
    }

    const avgLuminance = luminanceSum / totalPixels;

    // Find histogram min and max luminance with counts
    let minLuminance = 0;
    let maxLuminance = 255;
    for (let i = 0; i < 256; i++) {
      if (histogram[i] > totalPixels * 0.01) { // 1% threshold
        minLuminance = i;
        break;
      }
    }
    for (let i = 255; i >= 0; i--) {
      if (histogram[i] > totalPixels * 0.01) {
        maxLuminance = i;
        break;
      }
    }

    const luminanceRange = maxLuminance - minLuminance;

    let brightnessAdjustment = 0;
    let contrastAdjustment = 0;

    // Adjust brightness based on average luminance
    if (avgLuminance < 100) {
      brightnessAdjustment = 100 - avgLuminance;
    } else if (avgLuminance > 150) {
      brightnessAdjustment = 150 - avgLuminance;
    }

    // Adjust contrast based on luminance range
    if (luminanceRange < 100) {
      contrastAdjustment = (100 - luminanceRange) * 0.5;
    }

    // Clamp adjustments
    brightnessAdjustment = Math.round(clampValue(brightnessAdjustment, -50, 50));
    contrastAdjustment = Math.round(clampValue(contrastAdjustment, 0, 50));

    return { brightnessAdjustment, contrastAdjustment };
  };

  // Compute White Balance Gains
  const computeWhiteBalanceGains = (imageData) => {
    const data = imageData.data;
    let rSum = 0;
    let gSum = 0;
    let bSum = 0;
    const totalPixels = data.length / 4;

    for (let i = 0; i < data.length; i += 4) {
      rSum += data[i];
      gSum += data[i + 1];
      bSum += data[i + 2];
    }

    const rAvg = rSum / totalPixels;
    const gAvg = gSum / totalPixels;
    const bAvg = bSum / totalPixels;

    const grayAvg = (rAvg + gAvg + bAvg) / 3;

    const redGain = grayAvg / rAvg;
    const greenGain = grayAvg / gAvg;
    const blueGain = grayAvg / bAvg;

    return { redGain, greenGain, blueGain };
  };

  // Apply White Balance Correction
  const applyWhiteBalanceCorrection = (imageData, redGain, greenGain, blueGain) => {
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] = clampValue(data[i] * redGain, 0, 255);
      data[i + 1] = clampValue(data[i + 1] * greenGain, 0, 255);
      data[i + 2] = clampValue(data[i + 2] * blueGain, 0, 255);
    }
  };

  // Compute Saturation Adjustment
  const computeSaturationAdjustment = (imageData) => {
    const data = imageData.data;
    let totalSaturation = 0;
    const totalPixels = data.length / 4;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i] / 255;
      const g = data[i + 1] / 255;
      const b = data[i + 2] / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      const delta = max - min;

      let saturation = 0;
      if (max !== 0) {
        saturation = delta / max;
      }

      totalSaturation += saturation;
    }

    const avgSaturation = totalSaturation / totalPixels;

    let saturationAdjustment = 0;

    if (avgSaturation < 0.3) {
      saturationAdjustment = (0.5 - avgSaturation) * 100;
    }

    saturationAdjustment = Math.round(clampValue(saturationAdjustment, 0, 30));

    return saturationAdjustment;
  };

  // Estimate Noise Level
  const estimateNoise = (imageData) => {
    const data = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
    let noiseSum = 0;
    let count = 0;

    for (let y = 0; y < height - 1; y++) {
      for (let x = 0; x < width - 1; x++) {
        const idx = (y * width + x) * 4;
        const idxRight = (y * width + (x + 1)) * 4;
        const idxDown = ((y + 1) * width + x) * 4;

        const diffRight =
          Math.abs(data[idx] - data[idxRight]) +
          Math.abs(data[idx + 1] - data[idxRight + 1]) +
          Math.abs(data[idx + 2] - data[idxRight + 2]);

        const diffDown =
          Math.abs(data[idx] - data[idxDown]) +
          Math.abs(data[idx + 1] - data[idxDown + 1]) +
          Math.abs(data[idx + 2] - data[idxDown + 2]);

        noiseSum += diffRight + diffDown;
        count += 2;
      }
    }

    const avgNoise = noiseSum / (count * 3);

    return avgNoise;
  };

  // Compute Noise Reduction Value
  const computeNoiseReductionValue = (noiseLevel) => {
    let noiseReductionValue = 0;

    if (noiseLevel > 20) {
      noiseReductionValue = (noiseLevel - 20) / 5;
    }

    noiseReductionValue = Math.round(clampValue(noiseReductionValue, 0, 10));

    return noiseReductionValue;
  };

  // Compute Sharpness Value
  const computeSharpnessValue = (imageData) => {
    const data = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
    let edgeSum = 0;
    let count = 0;

    for (let y = 1; y < height - 1; y++) {
      for (let x = 1; x < width - 1; x++) {
        const idx = (y * width + x) * 4;

        // Compute gradients
        const gx =
          -data[((y - 1) * width + (x - 1)) * 4] -
          2 * data[(y * width + (x - 1)) * 4] -
          data[((y + 1) * width + (x - 1)) * 4] +
          data[((y - 1) * width + (x + 1)) * 4] +
          2 * data[(y * width + (x + 1)) * 4] +
          data[((y + 1) * width + (x + 1)) * 4];

        const gy =
          -data[((y - 1) * width + (x - 1)) * 4] -
          2 * data[((y - 1) * width + x) * 4] -
          data[((y - 1) * width + (x + 1)) * 4] +
          data[((y + 1) * width + (x - 1)) * 4] +
          2 * data[((y + 1) * width + x) * 4] +
          data[((y + 1) * width + (x + 1)) * 4];

        const gradientMagnitude = Math.sqrt(gx * gx + gy * gy);
        edgeSum += gradientMagnitude;
        count++;
      }
    }

    const avgEdge = edgeSum / count;

    let sharpnessValue = 0;

    if (avgEdge < 50) {
      sharpnessValue = (50 - avgEdge) / 5;
    }

    sharpnessValue = Math.round(clampValue(sharpnessValue, 0, 5));

    return sharpnessValue;
  };

const saveAllBufferedSettings = async () => {
  saveCurrentImageSettings(); // Save current image settings first

  const modifiedImages = []; // Collect modified images and their indices

  const promises = images.map((imageSrc, index) => {
    const settings = imageSettings.current[index];
    if (!settings) return Promise.resolve(); // No changes for this image

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // To avoid CORS issues if images are from different origin
      img.src = URL.createObjectURL(imageSrc); // Convert File object to URL

      img.onload = () => {
        const offCanvas = document.createElement('canvas');
        const ctx = offCanvas.getContext('2d');
        offCanvas.width = img.width;
        offCanvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Apply settings to the off-screen canvas
        applySettingsToContext(ctx, settings, offCanvas.width, offCanvas.height);

        const dataUrl = offCanvas.toDataURL('image/png');
        console.log(`Modified image ${index} dataUrl:`, dataUrl); // Debugging

        const file = dataURLToFile(dataUrl, `image_${index}.png`);
        if (file) {
          modifiedImages.push({ file, index }); // Collect modified image and index
        }

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(img.src);

        resolve();
      };

      img.onerror = (err) => {
        console.error(`Failed to load image at index ${index}:`, err);
        reject(err);
      };
    });
  });

  try {
    await Promise.all(promises);
    if (modifiedImages.length > 0) {
      onSave(modifiedImages); // Pass the array of modified images to onSave
    }
    setUnsavedChanges(new Set()); // Clear unsaved changes after saving
    onClose(); // Close the modal after all images are saved
  } catch (error) {
    console.error('Error saving all images:', error);
    // Optionally, handle the error (e.g., show a notification)
  }
};


  // Helper function to apply settings to a canvas context
  const applySettingsToContext = (ctx, settings, width, height) => {
    const {
      brightness,
      contrast,
      saturation,
      sharpness,
      noiseReduction,
      gamma,
      hue,
      redGain,
      greenGain,
      blueGain,
      whiteBalanceApplied,
    } = settings;
    let imageData = ctx.getImageData(0, 0, width, height);

    // Apply white balance correction if gains are set
    if (whiteBalanceApplied) {
      applyWhiteBalanceCorrection(imageData, redGain, greenGain, blueGain);
    }

    // Apply filters step by step
    imageData = applyBrightnessContrast(imageData, brightness, contrast);
    imageData = applySaturation(imageData, saturation);
    imageData = applyGamma(imageData, gamma);
    imageData = applyHueRotation(imageData, hue);
    imageData = applyNoiseReduction(imageData, noiseReduction);
    imageData = applySharpness(imageData, sharpness);

    ctx.putImageData(imageData, 0, 0);
  };

  // Handle switching to the next image
  const goToNextImage = () => {
    saveCurrentImageSettings(); // Save current image settings before switching
    const nextIndex = (activeImageIndex + 1) % images.length;
    setActiveImageIndex(nextIndex);
  };

  // Handle switching to the previous image
  const goToPreviousImage = () => {
    saveCurrentImageSettings(); // Save current image settings before switching
    const prevIndex = (activeImageIndex - 1 + images.length) % images.length;
    setActiveImageIndex(prevIndex);
  };

const handleSave = () => {
  saveCurrentImageSettings();
  const canvas = canvasRef.current;
  if (!canvas) {
    console.error("Canvas is not available.");
    return;
  }

  // Get the data URL from the canvas
  const dataUrl = canvas.toDataURL('image/png');

  if (!dataUrl) {
    console.error("Failed to get data URL from canvas.");
    return;
  }

  console.log("Generated data URL:", dataUrl);  // Confirm if this logs a valid URL

  // Convert the data URL to a File object
  const file = dataURLToFile(dataUrl, `image_${activeImageIndex}.png`);

  if (!file) {
    console.error("Failed to create File from data URL.");
    return;
  }

  // Pass the File object to onSave
  onSave([{ file, index: activeImageIndex }]);

  // Update unsavedChanges state
  setUnsavedChanges((prev) => {
    const newSet = new Set(prev);
    newSet.delete(activeImageIndex);
    return newSet;
  });
};



  // Handle closing the modal
  const handleClose = () => {
    if (unsavedChanges.size > 0) {
      setShowConfirmationModal(true); // Show confirmation modal if there are unsaved changes
    } else {
      onClose(); // Close the modal directly if there are no unsaved changes
    }
  };

  // Confirm saving all changes and closing
  const handleConfirmSaveAndClose = async () => {
    setShowConfirmationModal(false);
    await saveAllBufferedSettings(); // Save all buffered settings
  };

  // Cancel saving and just close the modal
  const handleCancelSaveAndClose = () => {
    setShowConfirmationModal(false);
    onClose(); // Close the modal without saving
  };

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2>{t('Edit Image')}</h2>

        <div className="image-wrapper">
          <button
            onClick={goToPreviousImage}
            disabled={images.length <= 1}
            className="image-navigation-button"
          >
            <ChevronLeftIcon />
          </button>
          <canvas ref={canvasRef} className="image-canvas"></canvas>
          <button
            onClick={goToNextImage}
            disabled={images.length <= 1}
            className="image-navigation-button"
          >
            <ChevronRightIcon />
          </button>
        </div>

        <div className="scrollable-content">
          <div className="controls">
            <div className="slider-group">
              <label>{t('Brightness')}: {brightness}</label>
              <input
                type="range"
                min="-100"
                max="100"
                value={brightness}
                onChange={(e) => {
                  setBrightness(parseInt(e.target.value));
                  setUnsavedChanges((prev) => {
                    const newSet = new Set(prev);
                    newSet.add(activeImageIndex);
                    return newSet;
                  });
                }}
              />
            </div>
            <div className="slider-group">
              <label>{t('Contrast')}: {contrast}</label>
              <input
                type="range"
                min="-100"
                max="100"
                value={contrast}
                onChange={(e) => {
                  setContrast(parseInt(e.target.value));
                  setUnsavedChanges((prev) => {
                    const newSet = new Set(prev);
                    newSet.add(activeImageIndex);
                    return newSet;
                  });
                }}
              />
            </div>
            <button
              className="advanced-settings-toggle"
              onClick={() => setAdvancedSettings(!advancedSettings)}
            >
              {advancedSettings ? t('Hide Advanced Settings') : t('Show Advanced Settings')}
            </button>

            {advancedSettings && (
              <div className="advanced-controls">
                <div className="slider-group">
                  <label>{t('Saturation')}: {saturation}</label>
                  <input
                    type="range"
                    min="-100"
                    max="100"
                    value={saturation}
                    onChange={(e) => {
                      setSaturation(parseInt(e.target.value));
                      setUnsavedChanges((prev) => {
                        const newSet = new Set(prev);
                        newSet.add(activeImageIndex);
                        return newSet;
                      });
                    }}
                  />
                </div>
                <div className="slider-group">
                  <label>{t('Sharpness')}: {sharpness}</label>
                  <input
                    type="range"
                    min="0"
                    max="10"
                    value={sharpness}
                    onChange={(e) => {
                      setSharpness(parseInt(e.target.value));
                      setUnsavedChanges((prev) => {
                        const newSet = new Set(prev);
                        newSet.add(activeImageIndex);
                        return newSet;
                      });
                    }}
                  />
                </div>
                <div className="slider-group">
                  <label>{t('Noise Reduction')}: {noiseReduction}</label>
                  <input
                    type="range"
                    min="0"
                    max="10"
                    value={noiseReduction}
                    onChange={(e) => {
                      setNoiseReduction(parseInt(e.target.value));
                      setUnsavedChanges((prev) => {
                        const newSet = new Set(prev);
                        newSet.add(activeImageIndex);
                        return newSet;
                      });
                    }}
                  />
                </div>
                <div className="slider-group">
                  <label>{t('Gamma')}: {gamma}</label>
                  <input
                    type="range"
                    min="0.5"
                    max="2"
                    step="0.1"
                    value={gamma}
                    onChange={(e) => {
                      setGamma(parseFloat(e.target.value));
                      setUnsavedChanges((prev) => {
                        const newSet = new Set(prev);
                        newSet.add(activeImageIndex);
                        return newSet;
                      });
                    }}
                  />
                </div>
                <div className="slider-group">
                  <label>{t('Hue')}: {hue}</label>
                  <input
                    type="range"
                    min="-180"
                    max="180"
                    value={hue}
                    onChange={(e) => {
                      setHue(parseInt(e.target.value));
                      setUnsavedChanges((prev) => {
                        const newSet = new Set(prev);
                        newSet.add(activeImageIndex);
                        return newSet;
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="auto-optimize-controls">
              <button className="auto-optimize-button" onClick={autoOptimize}>
                {t('Auto-Optimize')}
              </button>
              <button className="reset-button" onClick={resetFilters}>
                {t('Reset')}
              </button>
            </div>
          </div>
        </div>

        <div className="modal-actions">
          <button onClick={handleSave} className="save-button">
            {t('Save')}
          </button>
          <button onClick={handleClose} className="cancel-button">
            {t('Close')}
          </button>
        </div>

        {/* Confirmation Modal */}
        <ConfirmationModal
          isOpen={showConfirmationModal}
          onConfirm={handleConfirmSaveAndClose}
          onCancel={handleCancelSaveAndClose}
          message={t('You have unsaved changes. Do you want to save them before closing?')}
        />
      </div>
    </div>
  );

};

export default ImageEditorModal;
